<template>
  <main id="site-wrapper">
    <section class="section">
      <div class="container-fluid">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name:'user-management' }">
              Admin
            </router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name:'user-management' }">
              User Management
            </router-link>
          </li>
          <li class="breadcrumb-item active">
            Plans &amp; Role
          </li>
        </ol>
        <div class="section-header">
          <div class="section-header-title">
            <router-link
              class="section-header-back"
              :to="{name: 'user-management'}"
            >
              <i class="fas fa-arrow-circle-left" />
            </router-link>
            <h1>Plans &amp; Role</h1>
          </div>
        </div>
        <div
          v-if="curUser.id"
          class="row row-has-panel"
        >
          <div class="col-12">
            <p class="m-0">
              Editing {{ curUser.name }} {{ curUser.last_name }} ({{ curUser.email }})
            </p>
          </div>
        </div>
        <div class="row row-has-panel">
          <div class="col-xl-6">
            <div class="section-panel">
              <div class="section-panel-header">
                <div class="section-panel-header-title">
                  <h3>Plan</h3>
                </div>
              </div>
              <div class="section-panel-body">
                <div class="plan-subtitle">
                  <h4>Standard Plan</h4>
                </div>
                <div
                  v-for="plan in visiblePlanList"
                  :key="plan.id"
                  class="plan-option"
                  :class="{'is-checked': curPlanId === plan.id }"
                >
                  <div class="plan-option-header">
                    <span
                      :ref="`span-plan-${plan.id}`"
                      data-trigger="expandable"
                      @click="slideToggle(`plan-${plan.id}`)"
                    >
                      {{ plan.name }}
                    </span>
                    <div class="plan-option-checkbox">
                      <input
                        :id="`check-plan-${plan.id}`"
                        type="checkbox"
                        :checked="curPlanId === plan.id"
                        @change="setCurrentPlan(plan.id, $event)"
                      >
                      <label :for="`check-plan-${plan.id}`" />
                    </div>
                  </div>
                  <div
                    :ref="`div-plan-${plan.id}`"
                    class="expandable"
                  >
                    <div class="expandable-content">
                      <p>
                        {{ plan.description }}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  v-if="currentPlan && visibleAddonList.length"
                  class="plan-subtitle"
                >
                  <h4>Available Addons</h4>
                </div>
                <div
                  v-for="addon in visibleAddonList"
                  :key="`avl-${addon.id}`"
                  class="plan-option"
                  :class="{'is-checked': isIncludeAddon(addon.id)}"
                >
                  <div class="plan-option-header">
                    <span
                      :ref="`span-avl-${addon.id}`"
                      data-trigger="expandable"
                      @click="slideToggle(`avl-${addon.id}`)"
                    >
                      {{ addon.name }}
                    </span>
                    <div class="plan-option-checkbox">
                      <input
                        :id="`check-avl-addon-${addon.id}`"
                        type="checkbox"
                        :checked="isIncludeAddon(addon.id)"
                        @change="changeAddon(addon.id, $event)"
                      >
                      <label :for="`check-avl-addon-${addon.id}`" />
                    </div>
                  </div>
                  <div
                    :ref="`div-avl-${addon.id}`"
                    class="expandable"
                  >
                    <div class="expandable-content">
                      <p>
                        {{ addon.description }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="curUser.id"
            class="col-xl col-sm-6 mt-2 mt-xl-0"
          >
            <div class="section-panel">
              <div class="section-panel-header">
                <div class="section-panel-header-title">
                  <h3>Role</h3>
                </div>
              </div>
              <div class="section-panel-body">
                <div
                  v-for="role in availableRoleList"
                  :key="`role-${role.id}`"
                  v-tooltip.left="{content: toolTipText(role.display_name)}"
                  class="custom-checkbox"
                >
                  <div>
                    <input
                      :id="`check-role-${role.id}`"
                      :disabled="!canUserSelectRole(role.display_name)"
                      :checked="role.id === curRoleId"
                      type="checkbox"
                      @change="setCurrentRole(role.id, $event)"
                    >
                    <label :for="`check-role-${role.id}`">{{ role.display_name }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="curUser.id && isShowGotcha"
            class="col-xl col-sm-6 mt-2 mt-xl-0"
          >
            <gotcha
              :tip-list="tipList"
              @close="isShowGotcha = false"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <hr>
            <div
              class="text-right"
            >
              <router-link
                class="btn btn-text"
                :to="{ name:'user-management' }"
              >
                Revert changes
              </router-link>

              <span v-if="currentPlan">
                <a
                  id="proceed-to-summary"
                  class="btn btn-primary text-white"
                  @click.prevent="showConfirmation"
                >
                  Confirm Changes
                </a>
              </span>
              <span v-else>
                <a class="btn btn-outline-dark disabled">
                  Please make a change
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <confirm-dialog
      v-if="isShowConfirmation"
      :title="dialogTitle"
      :description="dialogDescription"
      @no="cancelUpdate"
      @yes="updatePlanRole"
    />
  </main>
</template>
<script>
import {userStore} from '@/store/__STORE_user';
import {billingStore} from '@/store/__STORE_billing';
import {mainStore} from '@/store/__STORE_main';
import {callStore} from '@/store/__STORE_call';
import Gotcha from '@/components/Gotcha.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import PlanMixin from '@/mixins/PlanMixin';
import axios from 'axios';

export default {
  name: 'PlansRolePP',
  components: {
    Gotcha,
    ConfirmDialog,
  },
  mixins: [PlanMixin],
  props: {
    id: {
      type: [Number, String],
      default: null,
    },
    prevRoute: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      curPlanId: '',
      curRoleId: '',
      curAddons: [],
      curUser: '',
      isShowGotcha: true,
      tipList: [
        'Select the standard plan you need',
        'Turn on the features you need by picking add on\'s such as Video Gateway or XR Gateway',
        'Add storage for recording',
      ],
      preview: {},
      isShowConfirmation: false,
      newRoleName: '',
      newPlanName: '',
      dialogTitle: 'You are about to make the following changes are you sure?',
    };
  },
  computed: {
    licenses: () => userStore.licenses,
    userList: () => userStore.subUsers,
    roleList: () => billingStore.roles,
    visiblePlanList() {
      const temp = this.licenses.filter((plan) => {
        return plan.type !== 'addon' && plan.available > 0;
      });
      const trialCount = this.licenses.length === 0 ? this.userList.filter((user) => user.billing_plan.name === 'Trial').length : 4;
      if ((this.curUser.billing_plan && this.curUser.billing_plan.name === 'Trial') || trialCount < 3) {
        const trialPlan = {
          id: 7,
          key: 'pp-trial',
          name: 'Trial',
          description: 'Lets you try out most of the features for 30 days',
          assigned: 3,
          used: 1,
          available: 2,
        };
        temp.unshift(trialPlan);
      } else {
        const freePlan = {
          id: 6,
          key: 'pp-no-license',
          name: 'No License',
          description: 'No license, user cannot access any features',
          assigned: 0,
          used: 0,
          available: 0,
        };
        temp.unshift(freePlan);
      }
      return temp;
    },
    visibleAddonList() {
      return this.licenses.filter((addon) => {
        return addon.type !== 'plan' && addon.available > 0;
      });
    },
    currentPlan() {
      return this.curPlanId ? this.visiblePlanList.find((item) => item.id === this.curPlanId) : '';
    },
    currentRole() {
      return this.curRoleId ? this.roleList.find((item) => item.id === this.curRoleId) : '';
    },
    availableRoleList() {
      return this.roleList;
    },
    dialogDescription() {
      return 'You are updating ' + this.curUser.name + ' ' + this.curUser.last_name +
          ' to have the role of ' + this.newRoleName + ' and the plan ' + this.newPlanName;
    },
  },
  watch: {
    curPlanId(val) {
      setTimeout(() => {
        const vm = this;
        vm.visiblePlanList.forEach((element) => {
          if (element.id === val) {
            vm.slideExpand(`plan-${element.id}`, true);
          } else {
            vm.slideExpand(`plan-${element.id}`, false);
          }
        });
      }, 200);
    },
  },
  created() {
    userStore.getLicenses();
    if (this.id) {
      this.getSubUser();
    } else {
      this.getUserProfile();
    }
  },
  methods: {
    canUserSelectRole(role) {
      if (role === 'Regular user') {
        return this.id;
      }
      return true;
    },
    toolTipText(role) {
      if (role === 'Regular user' && !this.id) {
        return 'You cannot remove yourself as an admin.';
      }
      return '';
    },
    cancelUpdate() {
      this.newPlanName = '';
      this.newRoleName = '';
      this.isShowConfirmation = false;
    },
    showConfirmation() {
      this.newPlanName = this.currentPlan.name;
      this.newRoleName = this.currentRole.display_name;
      this.isShowConfirmation = true;
    },
    getSubUser() {
      userStore.getSubUser(this.id).then((response) => {
        this.curUser = response;
        this.initPlanRole();
      }).catch((err) => {
        this.$toast.error(err);
      });
    },
    getUserProfile() {
      userStore.getUserProfile().then((response) => {
        this.curUser = response.data;
        this.initPlanRole();
      }).catch((err) => {
        this.$toast.error(err);
      });
    },
    setCurrentPlan(id, event) {
      if (event.target.checked) {
        const vm = this;
        vm.curPlanId = id;
        vm.curAddons = [];
      } else {
        this.curPlanId = '';
        this.curAddons = [];
      }
    },
    setCurrentRole(id, event) {
      if (event.target.checked) {
        this.curRoleId = id;
      } else {
        this.curRoleId = '';
      }
    },
    slideToggle(id) {
      if (!this.$refs['div-' + id] || !this.$refs['span-' + id]) {
        return;
      }

      const expandWidget = this.$refs[`div-${id}`][0];
      const expandSpan = this.$refs[`span-${id}`][0];
      if (expandSpan.classList.value) {
        expandSpan.classList = [];
        expandWidget.style.maxHeight = '';
      } else {
        expandSpan.classList = ['is-active'];
        expandWidget.style.maxHeight = expandWidget.scrollHeight + 'px';
      }
    },
    slideExpand(id, status) {
      if (!this.$refs['div-' + id] || !this.$refs['span-' + id]) {
        return;
      }

      const expandWidget = this.$refs[`div-${id}`][0];
      const expandSpan = this.$refs[`span-${id}`][0];
      if (status) {
        expandSpan.classList = ['is-active'];
        expandWidget.style.maxHeight = expandWidget.scrollHeight + 'px';
      } else {
        expandSpan.classList = [];
        expandWidget.style.maxHeight = '';
      }
    },
    isIncludeAddon(id) {
      const findIndex = this.curAddons.findIndex((item) => item.id === id);
      if (findIndex >= 0) {
        this.slideExpand(`avl-${id}`, true);
        return true;
      } else {
        return false;
      }
    },
    changeAddon(id, event) {
      if (event.target.checked) {
        const curAddon = this.visibleAddonList.find((item) => item.id === id);
        this.curAddons.push(curAddon);

        if (curAddon.name === '100GB Storage for Recordings') {
          this.deselect500GbStorageAddon();
        }

        if (curAddon.name === '500GB Storage for Recordings') {
          this.deselect100GbStorageAddon();
        }
        this.slideExpand(`avl-${id}`, true);
      } else {
        const delIndex = this.curAddons.findIndex((item) => item.id === id);
        this.curAddons.splice(delIndex, 1);
        this.slideExpand(`avl-${id}`, false);
      }
    },
    deselect100GbStorageAddon() {
      const indexOf100 = this.curAddons.findIndex((curAddon) => {
        return curAddon.name === '100GB Storage for Recordings';
      });

      if (indexOf100 >= 0) {
        this.curAddons.splice(indexOf100, 1);
      }
    },
    deselect500GbStorageAddon() {
      const indexOf500 = this.curAddons.findIndex((curAddon) => {
        return curAddon.name === '500GB Storage for Recordings';
      });

      if (indexOf500 >= 0) {
        this.curAddons.splice(indexOf500, 1);
      }
    },
    initPlanRole() {
      if (this.curUser) {
        this.curPlanId = this.curUser.billing_plan ? this.curUser.billing_plan.id : 6;
        if (this.curUser.role) {
          const curRole = this.roleList.find((role) => role.display_name === this.curUser.role);
          this.curRoleId = curRole ? curRole.id : '';
        } else {
          this.curRoleId = '';
        }
        this.curAddons = [...this.curUser.addons];
      } else {
        this.curPlanId = '';
        this.curRoleId = '';
        this.curAddons = [];
      }
    },
    updatePlanRole() {
      const vm = this;
      vm.isShowConfirmation = false;
      if (!vm.curPlanId) {
        vm.$toast.error('Please select a plan!');
        return;
      }
      if (!vm.curRoleId) {
        vm.$toast.error('Please select a role!');
        return;
      }
      const tempRole = vm.currentRole.display_name;
      let curRoleName = '';
      if (tempRole === 'Admin') {
        curRoleName = 'channel_admin';
      } else {
        curRoleName = 'regular_channel_user';
      }

      const addonIds = vm.curAddons.map((curAddon) => {
        return curAddon.id;
      });

      if (vm.currentPlan.name === 'No License' && addonIds.length > 0) {
        vm.$toast.error('You must select a license to assign addons');
        return;
      }

      const params = {
        role: curRoleName,
        billing_plan_id: vm.curPlanId,
        addon_ids: addonIds,
        terms: 1,
      };

      mainStore.setLoading();

      callStore.clearVMR();

      axios({url: '/subusers/' + vm.curUser.id, data: params, method: 'PATCH'}).then((response) => {
        this.onUpdated();
      }).catch((err) => {
        if (err.response && err.response.data) {
          const data = err.response.data;
          vm.$toast.error(data.message);
          if (err.response.status === 422) {
            for (const messages of Object.values(data.errors)) {
              for (const msg of messages) {
                vm.$toast.error(msg);
              }
            }
          }
        } else {
          vm.$toast.error(err);
        }
      });
    },
    onUpdated() {
      userStore.getSubUsers().then(() => {
        this.$router.push({name: 'user-management'});
        this.$toast.success('Plan and addons updated successfully!');
      });
    },
  },
};
</script>
